$title-color: #2f1f19;
$border-color: #eae5e1;

.chips {
  &-container {
    display: flex;
    margin-right: 10px;
    margin-bottom: 15px;
    padding: 9px 15px;
    border-radius: 20px;
    height: 38px;
    border: 1px solid $border-color;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
  }

  &__content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  &__box {
    display: flex;
    align-items: center;
  }

  &__text {
    font-size: 15px;
    font-weight: 400;

    &-padding {
      padding-top: 3px;
    }
  }

  &-active {
    background-color: $colour-light-blue;
    border-color: #4264fd;
    color: #4264fd;

    &_dark {
      background-color: $colour-cocoa-brown;
      border-color: $colour-cocoa-brown;
      color: $colour-white;
    }
  }

  &__reset {
    margin-top: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }
}
