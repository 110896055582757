.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  text-decoration: none;

  &-tools {
    height: 40px;
    line-height: 38px;
    padding: 0 10px;
    color: #4264fd;
    font-size: 15px;
    font-family: $helvetica;
    text-decoration: none;
    border-radius: 7px;
    border: solid 1px #eae5e1;
    background-color: #fff;

    @include mq(330, 'max-width') {
      padding: 0 7px;
    }

    &:first-child {
      margin-right: 7px;
    }
  }

  &__label {
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
    width: 100%;
  }

  &__with-appearance {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    border-radius: 14px;
    background-color: $colour-white;
    font-size: 15px;
    color: $colour-white;
    transition: opacity 0.3s ease-in-out 0.3s;
    width: 100%;
  }

  &__blue--filled {
    background-color: $colour-neon-blue;

    &:disabled {
      background-color: $colour-neon-blue-light;
    }
  }

  &__blue--outlined {
    color: $colour-neon-blue;
    border: 1px solid $colour-grey-wild-sand;

    &:disabled {
      color: $colour-heathered-grey;
    }
  }
}
